import { scrollToHandler } from "thalia-molecules/custom-elements/scroll-to-anchor/scrollToHandler";

class LayoutFullsize extends HTMLElement {

  connectedCallback() {
    this.initHashListener();
    this.scrollToHash();
  }

  scrollToHash() {
    if (window.location.hash) {
      scrollToHandler(window.location.hash.substring(1));
    }
  }

  initHashListener() {
    window.addEventListener('hashchange', this.scrollToHash);
  }

}

if (!customElements.get("layout-fullsize")) {
  customElements.define("layout-fullsize", LayoutFullsize);
}
